import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { ApiError, PaginatedData, SearchParams } from '../common/types';

export enum DocumentTemplateType {
  BUSINESS_TO_BUSINESS = 'BUSINESS_TO_BUSINESS',
  WORK_AGREEMENT = 'WORK_AGREEMENT',
  WORK_AGREEMENT_WITHOUT_RIGHTS_TRANSFER = 'WORK_AGREEMENT_WITHOUT_RIGHTS_TRANSFER',
  SOCIAL_INSURANCE_AGREEMENT = 'SOCIAL_INSURANCE_AGREEMENT',
  ORDER_AGREEMENT = 'ORDER_AGREEMENT',
  COOPERATION_AGREEMENT = 'COOPERATION_AGREEMENT',
  WORK_AGREEMENT_PAYOUT_DOCUMENTS = 'WORK_AGREEMENT_PAYOUT_DOCUMENTS',
  ORDER_AGREEMENT_PAYOUT_DOCUMENTS = 'ORDER_AGREEMENT_PAYOUT_DOCUMENTS',
  COOPERATION_AGREEMENT_ADDITION = 'COOPERATION_AGREEMENT_ADDITION',
}

export enum DocumentTemplateKind {
  OTHER = 'OTHER',
  PIT_ROK_2023 = 'PIT_ROK_2023',
  PPK_INFORMATION = 'PPK_INFORMATION',
  PPK_REJECTION = 'PPK_REJECTION',
  POLITICAL_INFO = 'POLITICAL_INFO',
  PERSONAL_DATA_SUBMISSION_ZUS = 'PERSONAL_DATA_SUBMISSION_ZUS',
  LANGUAGE_DECLARATION = 'LANGUAGE_DECLARATION',
}

export const DocumentTemplateKindLabelMap: Record<
  DocumentTemplateKind,
  string
> = {
  OTHER: 'OTHER',
  PIT_ROK_2023: 'PIT_ROK_2023',
  PPK_INFORMATION: 'PPK_INFORMATION',
  PPK_REJECTION: 'PPK_REJECTION',
  POLITICAL_INFO: 'POLITICAL_INFO',
  PERSONAL_DATA_SUBMISSION_ZUS: 'PERSONAL_DATA_SUBMISSION_ZUS',
  LANGUAGE_DECLARATION: 'LANGUAGE_DECLARATION',
};

export const DocumentTemplateTypeLabelMap: Record<
  DocumentTemplateType,
  string
> = {
  BUSINESS_TO_BUSINESS: 'B2B',
  ORDER_AGREEMENT: 'Umowa zliecenia',
  SOCIAL_INSURANCE_AGREEMENT: 'ZUS',
  WORK_AGREEMENT: 'Umowa o dzielo',
  WORK_AGREEMENT_WITHOUT_RIGHTS_TRANSFER: 'Umowa o dzielo without transfer',
  COOPERATION_AGREEMENT: 'Cooperation agreement',
  WORK_AGREEMENT_PAYOUT_DOCUMENTS: 'Work effects (dzielo)',
  ORDER_AGREEMENT_PAYOUT_DOCUMENTS: 'Zlecenie mala',
  COOPERATION_AGREEMENT_ADDITION: 'Additional documents',
};

export type TemplateTableRowProps = {
  uid: string;
  type: DocumentTemplateType;
  isPublic: boolean;
  deleted: boolean;
  name: string;
  organization?: {
    uid: string;
    Representative: {
      uid: string;
      User: {
        uid: string;
        email: string;
      };
    };
  };
  fileUpload: {
    uid: string;
    key: string;
    mimetype: string;
    extension: string;
  };
};

interface CreateTemplateProps {
  file: File;
  data: {
    name: string;
    isPublic: boolean;
    type: DocumentTemplateType;
    pandadocId: string;
    kind: DocumentTemplateKind;
  };
}

export const templatesApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'templates',
  tagTypes: ['Templates'],
  endpoints: (build) => ({
    getTemplates: build.query<
      { data: PaginatedData<TemplateTableRowProps> },
      SearchParams
    >({
      providesTags: ['Templates'],
      query(params) {
        return {
          url: '/document-template',
          method: 'GET',
          params,
        };
      },
    }),
    deleteTemplate: build.mutation<void, string>({
      invalidatesTags: ['Templates'],
      query(uid) {
        return {
          url: `/document-template/${uid}`,
          method: 'DELETE',
        };
      },
    }),
    getPreviewUrl: build.query<{ data: { url: string } }, string>({
      query(fileUid) {
        return {
          url: `/file-upload/view-url/${fileUid}`,
          method: 'GET',
        };
      },
    }),
    createTemplate: build.mutation<null, CreateTemplateProps>({
      invalidatesTags: ['Templates'],
      async queryFn({ data, file }) {
        try {
          const uploadInfo = await axios.post(
            `${mainApiUrl}/file-upload/request`,
            {
              originalName: file.name,
              extension: file.name.split('.').at(-1),
              mimetype: file.type,
            },
          );

          await axios.put(uploadInfo.data.data.url, file, {
            headers: { 'Content-Type': file.type },
            transformRequest: (data, headers) => {
              delete headers?.['Authorization'];
              return data;
            },
          });

          await axios.post(`${mainApiUrl}/file-upload/confirm`, {
            size: file.size,
            uid: uploadInfo.data.data.uid,
          });

          await axios.post(`${mainApiUrl}/document-template`, {
            name: data.name,
            fileUploadUid: uploadInfo.data.data.uid,
            isPublic: data.isPublic,
            kind: data.kind,
            type: data.type || 'OTHER',
            pandadocId: data.pandadocId,
          });

          return {
            data: null,
          };
        } catch (err) {
          return { error: err as ApiError };
        }
      },
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useLazyGetPreviewUrlQuery,
} = templatesApi;
