import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import InsecureZone from 'src/common/components/InsecureZone';
import SecureZone from 'src/common/components/SecureZone';

import NotFound from './common/components/NotFound';

const Dashboard = lazy(() => import('./revolut'));
const Templates = lazy(() => import('./templates'));
const SupportMembers = lazy(() => import('./supportMembers'));
const SupportTags = lazy(() => import('./supportTags'));
const Support = lazy(() => import('./support'));
const SignIn = lazy(() => import('./auth/SignIn'));
const AsyncEvents = lazy(() => import('./asyncEvents'));

function App() {
  return (
    <Routes>
      <Route path="/" element={<SecureZone />}>
        <Route index element={<Dashboard />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/support-members" element={<SupportMembers />} />
        <Route path="/support-tags" element={<SupportTags />} />
        <Route path="/async-events" element={<AsyncEvents />} />
        <Route path="/support" element={<Support />} />
        <Route path="/404" element={<NotFound />} />
      </Route>
      <Route element={<InsecureZone />}>
        <Route path="/signIn" element={<SignIn />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
