import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { authApiUrl } from 'src/config';

export interface UserState {
  email: string;
  role: string;
  emailVerified: boolean;
  mfaEnabled: boolean;
}

export const commonApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: authApiUrl }),
  reducerPath: 'commonApi',
  endpoints: (build) => ({
    getUser: build.query<UserState, void>({
      query() {
        return {
          url: '/current-user',
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useLazyGetUserQuery } = commonApi;
