import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import NotificationsProvider from 'src/common/components/NotificationsProvider';

import App from './App';
import { store } from './common/store';
import theme from './common/theme';
import { SnackbarUtilsConfigurator } from './common/utils/snackbarUtils';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <NotificationsProvider>
      <Provider store={store}>
        <BrowserRouter>
          <SnackbarUtilsConfigurator />
          <CssBaseline />
          <App />
        </BrowserRouter>
      </Provider>
    </NotificationsProvider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
