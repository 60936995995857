import { Box, Drawer, List, ListItemIcon, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useResponsive from 'src/common/hooks/useResponsive';
import { getActive } from 'src/common/utils/urlUtils';

import Icon, { IconName } from '../Icon';
import { ListItemStyle, ListItemTextStyle } from './styles';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  role: string | null;
};

const Navbar = ({ isOpenSidebar, onCloseSidebar }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderListItem = (title: string, path: string, iconName: IconName) => {
    const active = getActive(path, pathname);

    return (
      <ListItemStyle active={active} onClick={() => navigate(path)}>
        <ListItemIcon sx={{ color: 'currentcolor', minWidth: '0', mr: 2 }}>
          <Icon name={iconName} />
        </ListItemIcon>

        <ListItemTextStyle
          primary={title}
          primaryTypographyProps={{
            noWrap: true,
            variant: active ? 'subtitle2' : 'body2',
          }}
        />
      </ListItemStyle>
    );
  };

  const renderContent = (
    <>
      <Stack
        spacing={3}
        sx={{
          py: 3,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Icon name="logo" width={152} height={44} />
        </Stack>
      </Stack>
      <Box>
        <List disablePadding sx={{ px: 2 }}>
          {renderListItem('Revolut', '/', 'finance')}
          {renderListItem('Templates', '/templates', 'finance')}
          {renderListItem('Support members', '/support-members', 'finance')}
          {renderListItem('Support', '/support', 'finance')}
          {renderListItem('Support Tags', '/support-tags', 'finance')}
          {renderListItem('Async events', '/async-events', 'finance')}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: 280 } }}
        >
          {renderContent}
        </Drawer>
      )}
      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 280,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
};

export default Navbar;
