import { createSlice } from '@reduxjs/toolkit';

import { commonApi, UserState } from './apis';

const initialState: UserState = {
    email: '',
    role: '',
    emailVerified: false,
    mfaEnabled: false,
};

export const userOrgSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(commonApi.endpoints.getUser.matchFulfilled, (state, action) => {
                state.email = action.payload.email;
                state.emailVerified = action.payload.emailVerified;
                state.role = action.payload.role;
                state.mfaEnabled = action.payload.mfaEnabled;
            });
    },
});

export default userOrgSlice.reducer;