import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import Mutex from 'src/common/services/asyncMutex';
import { authApiUrl } from 'src/config';

const mutex = new Mutex();

export type TokensData = {
  expires_in: number;
  access_token: string;
  refresh_token: string;
};

export function deleteTokens() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('token_expiration');
  localStorage.removeItem('refresh_token');
}

export function redirectToLogin() {
  deleteTokens();
  const params = new URLSearchParams({
    returnUrl: encodeURIComponent(
      window.location.pathname + window.location.search,
    ),
  });
  window.location.href = `/signIn?${params}`;
}

export function saveTokens(tokens: TokensData) {
  localStorage.setItem('access_token', tokens.access_token);
  localStorage.setItem(
    'token_expiration',
    (Math.floor(Date.now() / 1000) + tokens.expires_in).toString(),
  );
  localStorage.setItem('refresh_token', tokens.refresh_token);
}

export async function refreshToken() {
  const refreshToken = localStorage.getItem('refresh_token') || '';
  const response = await axios.post<TokensData>(`${authApiUrl}/token`, {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  });
  saveTokens(response.data);
}

export async function requestInterceptor(config: InternalAxiosRequestConfig) {
  if (config.url?.endsWith('/token')) {
    return config;
  }

  const release = await mutex.acquire();

  const authTokenExpiration = localStorage.getItem('token_expiration');

  if (!authTokenExpiration) {
    redirectToLogin();
    return config;
  }

  if (parseInt(authTokenExpiration) <= Math.floor(Date.now() / 1000)) {
    await refreshToken();
  }

  const token = localStorage.getItem('access_token') || '';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  config.headers['Authorization'] = `Bearer ${token}`;

  release();

  return config;
}

export async function responseErrorInterceptor(error: AxiosError) {
  if (error.response?.status === 401) {
    redirectToLogin();
  }

  return Promise.reject(error);
}

export function logout() {
  deleteTokens();

  // TODO: redirect to landing page
  redirectToLogin();
}
