import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { PaginatedData } from '../common/types';

export type TagType = 'GENERAL' | 'INTERNAL' | 'EXTERNAL';

export type SupportTagsData = {
  uid: string;
  name: string;
  type: TagType;
};

export const supportTagsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'supportTagsApi',
  tagTypes: ['Tags'],
  endpoints: (build) => ({
    getSupportTags: build.query<{ data: PaginatedData<SupportTagsData> }, void>(
      {
        providesTags: ['Tags'],
        query() {
          return {
            url: '/support-tag/list',
            method: 'GET',
          };
        },
      },
    ),
    createSupportTag: build.mutation<void, { name: string; type: string }>({
      invalidatesTags: ['Tags'],
      query(data) {
        return {
          url: '/support-tag',
          method: 'POST',
          data,
        };
      },
    }),
  }),
});

export const { useCreateSupportTagMutation, useGetSupportTagsQuery } =
  supportTagsApi;
