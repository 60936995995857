import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { authApiUrl } from 'src/config';

export type SingInPasswordResponse =
  | {
      code: string;
      mfaRequired: true;
    }
  | {
      mfaRequired: false;
      access_token: string;
      refresh_token: string;
      expires_in: number;
    };

export interface SignInPasswordPayload {
  email: string;
  password: string;
}

export interface SignInMFAPayload {
  token: string;
  code: string;
}

export const authApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: authApiUrl }),
  reducerPath: 'auth',
  endpoints: (build) => ({
    signInPassword: build.mutation<
      SingInPasswordResponse,
      SignInPasswordPayload
    >({
      query({ email, password }) {
        return {
          url: '/sign-in',
          method: 'POST',
          data: {
            email,
            password,
          },
        };
      },
    }),
    signInMFA: build.mutation<SingInPasswordResponse, SignInMFAPayload>({
      query(data) {
        return {
          url: '/mfa/verify-mfa-sign-in',
          method: 'POST',
          data,
        };
      },
    }),
  }),
});

export const { useSignInPasswordMutation, useSignInMFAMutation } = authApi;
