import {
  configureStore,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';
import { asyncEventsApi } from 'src/asyncEvents/asyncEvents.service';
import { authApi } from 'src/auth/auth.service';
import { revolutApi } from 'src/revolut/revolut.service';
import { supportMembersApi } from 'src/supportMembers/supportMembers.service';
import { supportTagsApi } from 'src/supportTags/supportTags.service';
import { templatesApi } from 'src/templates/templates.service';

import { commonApi } from './apis';
import commonSlices from './slices';
import SnackBarUtils from './utils/snackbarUtils';

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    SnackBarUtils.error(action.payload.code);
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [commonApi.reducerPath]: commonApi.reducer,
    [revolutApi.reducerPath]: revolutApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [supportMembersApi.reducerPath]: supportMembersApi.reducer,
    [supportTagsApi.reducerPath]: supportTagsApi.reducer,
    [asyncEventsApi.reducerPath]: asyncEventsApi.reducer,
    common: commonSlices,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      rtkQueryErrorLogger,
      commonApi.middleware,
      revolutApi.middleware,
      templatesApi.middleware,
      authApi.middleware,
      supportMembersApi.middleware,
      supportTagsApi.middleware,
      asyncEventsApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
