import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { PaginatedData, SearchParams } from '../common/types';

type AssertionResponse = {
  data: {
    assertionRequested: boolean;
    expireDate: string;
  };
};

export type PaymentAccountPoolTableRowProps = {
  uid: string;
  status: string;
  currency: string;
  balance: number;
  kind: 'CURRENCY' | 'INCOME' | 'SOCIAL' | 'VALUE' | 'MERCHANT';
  revolutPaymentAccount: {
    currency: 'PLN';
    state: 'ACTIVE';
  };
};

export type ExchangeCurrencyAdmin = {
  amount: number;
  toRevolutPaymentAccountUid: string;
  fromRevolutPaymentAccountUid: string;
  toCurrency: string;
  fromCurrency: string;
};

export const revolutApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'revolut',
  tagTypes: ['PaymentAccountPool', 'Assertion', 'InternalPaymentAccounts'],
  endpoints: (build) => ({
    getAssertionStatus: build.query<AssertionResponse, void>({
      providesTags: ['Assertion'],
      query() {
        return {
          url: '/admin/assertion-status',
          method: 'GET',
        };
      },
    }),
    updateAssertionStatus: build.mutation<void, string>({
      invalidatesTags: ['Assertion'],
      query(assertionCode) {
        return {
          url: '/admin/update-assertion',
          method: 'POST',
          data: {
            assertionCode,
          },
        };
      },
    }),
    getPaymentAccountsPool: build.query<
      { data: PaginatedData<PaymentAccountPoolTableRowProps> },
      SearchParams
    >({
      providesTags: ['PaymentAccountPool'],
      query(params) {
        return {
          url: '/admin/payment-account-pool',
          method: 'GET',
          params,
        };
      },
    }),
    getInternalAccounts: build.query<
      { data: PaginatedData<PaymentAccountPoolTableRowProps> },
      void
    >({
      providesTags: ['InternalPaymentAccounts'],
      query() {
        return {
          url: '/payment-account/list',
          method: 'GET',
          params: {
            filter: [
              {
                field: 'type',
                type: '=',
                value: 'INTERNAL',
              },
            ],
          },
        };
      },
    }),
    addPaymentAccountIntoPool: build.mutation<void, string>({
      invalidatesTags: ['PaymentAccountPool'],
      query(accountId) {
        return {
          url: '/admin/add-account-to-pool',
          method: 'POST',
          data: {
            accountId,
          },
        };
      },
    }),
    registerInternalAccount: build.mutation<
      void,
      { currency: string; kind: string; accountId?: string }
    >({
      invalidatesTags: ['PaymentAccountPool', 'InternalPaymentAccounts'],
      query(data) {
        return {
          url: '/admin/register-internal',
          method: 'POST',
          data,
        };
      },
    }),
    exchangeCurrencyAdmin: build.mutation<void, ExchangeCurrencyAdmin>({
      query(data) {
        return {
          url: '/admin/raw-revolut/exchange',
          method: 'POST',
          data: {
            ...data,
            amountType: 'from',
          },
        };
      },
    }),
  }),
});

export const {
  useGetPaymentAccountsPoolQuery,
  useGetAssertionStatusQuery,
  useUpdateAssertionStatusMutation,
  useAddPaymentAccountIntoPoolMutation,
  useRegisterInternalAccountMutation,
  useGetInternalAccountsQuery,
  useExchangeCurrencyAdminMutation,
} = revolutApi;
