import createAvatar from 'src/common/utils/createAvatar';

import Avatar, { Props as AvatarProps } from '../../Avatar';

type MyAvatarProps = {
  displayName: string;
  imageUrl?: string;
} & AvatarProps;

export default function MyAvatar({
  displayName,
  imageUrl,
  ...other
}: MyAvatarProps) {
  return (
    <Avatar
      src={imageUrl}
      alt={displayName}
      color={imageUrl ? 'default' : createAvatar(displayName).color}
      {...other}
    >
      {createAvatar(displayName).name}
    </Avatar>
  );
}
