import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { PaginatedData } from '../common/types';

export type SupportMemberData = {
  uid: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  status: string;
  updatedAt: string;
  User: {
    uid: string;
    email: string;
  };
  SupportTagsOnSupportMembers: Array<{
    supportTag: {
      uid: string;
      name: string;
    };
  }>;
};

export type AssignTagsPayload = {
  uid: string;
  addedTags: string[];
  removedTags: string[];
};

export const supportMembersApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'supportMembersApi',
  tagTypes: ['Members'],
  endpoints: (build) => ({
    getSupportMembers: build.query<
      { data: PaginatedData<SupportMemberData> },
      void
    >({
      providesTags: ['Members'],
      query() {
        return {
          url: '/support/list',
          method: 'GET',
        };
      },
    }),
    createSupportMember: build.mutation<void, string>({
      invalidatesTags: ['Members'],
      query(email) {
        return {
          url: '/invitation',
          method: 'POST',
          data: {
            email,
            type: 'SUPPORT',
          },
        };
      },
    }),
    activateSupportMember: build.mutation<void, string>({
      invalidatesTags: ['Members'],
      query(uid) {
        return {
          url: `/support/activate/${uid}`,
          method: 'POST',
        };
      },
    }),
    deactivateSupportMember: build.mutation<void, string>({
      invalidatesTags: ['Members'],
      query(uid) {
        return {
          url: `/support/disable/${uid}`,
          method: 'POST',
        };
      },
    }),
    assignSupportTags: build.mutation<void, AssignTagsPayload>({
      invalidatesTags: ['Members'],
      query({ uid, ...data }) {
        return {
          url: `/support/assign-tags/${uid}`,
          method: 'POST',
          data,
        };
      },
    }),
  }),
});

export const {
  useGetSupportMembersQuery,
  useCreateSupportMemberMutation,
  useActivateSupportMemberMutation,
  useDeactivateSupportMemberMutation,
  useAssignSupportTagsMutation,
} = supportMembersApi;
