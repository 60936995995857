import {
    ListItemButton,
    ListItemButtonProps,
    ListItemText,
    ListItemTextProps,
    ListSubheader,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import cssStyles from '../../utils/cssStyles';

export interface ListItemStyleProps extends ListItemButtonProps {
    active: boolean;
}

export const ListItemStyle = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'active',
})<ListItemStyleProps>(({ active, theme }) => ({
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    height: 48,
    // Active item
    ...(active && {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    }),
}));

interface ListItemTextStyleProps extends ListItemTextProps {
    isCollapse?: boolean;
}

export const ListItemTextStyle = styled(ListItemText)<ListItemTextStyleProps>(({ theme }) => ({
    transition: theme.transitions.create(['width', 'opacity'], {
        duration: theme.transitions.duration.shorter,
    }),
}));

export const ListSubheaderStyle = styled(ListSubheader)(({ theme }) => ({
    ...theme.typography.overline,
    borderRadius: theme.shape.borderRadius,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
    ...cssStyles(theme).bgBlur(),
}));
