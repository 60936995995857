import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { PaginatedData, SearchParams } from '../common/types';

export type AsyncEventsData = {
  uid: string;
  updatedAt: string;
  status: 'PROCESSED' | 'CREATED' | 'FAILED';
  groupId: string;
  type: string;
  errorReason: string;
  data: Record<string, string>;
};

export const asyncEventsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'asyncEventsApi',
  tagTypes: ['AsyncEvents'],
  endpoints: (build) => ({
    getAsyncEvents: build.query<
      { data: PaginatedData<AsyncEventsData> },
      { params: SearchParams; source: 'api' | 'payment' }
    >({
      providesTags: ['AsyncEvents'],
      query({ params, source }) {
        return {
          url: `/async-event/${source === 'api' ? '' : 'payment/'}list`,
          method: 'GET',
          params,
        };
      },
    }),
    retryEvent: build.mutation<
      void,
      { uid: string; source: 'api' | 'payment' }
    >({
      invalidatesTags: ['AsyncEvents'],
      query({ uid, source }) {
        return {
          url: `/async-event/${source === 'api' ? '' : 'payment/'}retry/${uid}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const { useRetryEventMutation, useGetAsyncEventsQuery } = asyncEventsApi;
