import { matchPath } from 'react-router-dom';

export function getActive(path: string, pathname: string) {
  return path
    ? !!matchPath({ path: path, end: path === '/' }, pathname)
    : false;
}

export const buildRedirectUrl = (returnUrl: string | null) => {
  if (!returnUrl) {
    return '/';
  }

  returnUrl = decodeURIComponent(returnUrl);
  if (returnUrl[returnUrl.length - 1] === '/') {
    returnUrl = returnUrl.slice(0, -1);
  }

  return returnUrl || '/';
};
